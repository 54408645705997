import { useMutation } from 'react-query'
import { CheckoutValidationRequest } from '../app/types'
import { validateGiftCardCheckout } from '../api/api'
import { useReCaptcha } from './useRecaptcha'

interface MutationProps {
  recaptchaToken: string
}

export const useCheckoutValidation = () => {
  const { getRecaptcha } = useReCaptcha()

  const fetchRecaptchaToken = async (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      getRecaptcha.mutate('EGiftCard_GoToCheckout', {
        onSuccess: resolve,
        onError: reject
      })
    })
  }

  return useMutation(
    async (restaurantGuid: string) => {
      const recaptchaToken = await fetchRecaptchaToken()

      if (!recaptchaToken) {
        throw new Error('Recaptcha token is missing')
      }

      const request: CheckoutValidationRequest =
        buildGiftCardOrderValidationRequest({
          recaptchaToken
        })
      await validateGiftCardCheckout(request, restaurantGuid)
    },
    {
      retry: false
    }
  )
}

const buildGiftCardOrderValidationRequest = ({
  recaptchaToken
}: MutationProps): CheckoutValidationRequest => {
  return {
    recaptchaToken: recaptchaToken
  }
}
