import React from 'react'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { useRestaurantContext } from '@local/restaurant'
import { useGiftCardContext } from '../../context/GiftCardContext'
import { minorToMajorUnits } from '../../Utils/configUtils'
import { MOCK_GIFTCARD } from '../../__mocks__/GiftCardCard'
import './index.css'
import { useGiftCardConfigContext } from '../../context/GiftCardConfigContextProvider'
import { GiftCardImage } from '../GiftCardImage/GiftCardImage'
import { RestaurantLogo } from '../RestaurantLogo/RestaurantLogo'

type GifCardBalanceProps = {
  balance: number | undefined
}

export const GiftCardPreview = () => {
  const { giftCards, sender } = useGiftCardContext()
  const currentGiftCard =
    giftCards !== undefined && giftCards.length > 0
      ? giftCards[giftCards.length - 1]
      : MOCK_GIFTCARD
  const { recipient, message, amount } = currentGiftCard
  const giftCardValue = amount ? minorToMajorUnits(amount.value) : 0
  const receiver = recipient.to === '' ? recipient.email : recipient.to
  const { eGiftCardImage, restaurantName } = useGiftCardConfigContext()

  return (
    <div className='flex flex-col pb-20'>
      <div className='text-center justify-center type-default'>
        <div className='type-large text-gray-100 font-medium pb-2 tracking-wide'>
          Give a gift from
        </div>
        <div
          data-testid='restaurant-name'
          className='type-headline-3 font-medium pb-9'
        >
          {restaurantName}
        </div>
        <div className='bg-white w-[340px] md:w-[391px] h-auto shadow overflow-hidden rounded-[20px] type-default mx-auto pt-8'>
          <div className='flex justify-between mx-7 mb-5'>
            <div className='font-semibold text-pretty text-left'>
              {restaurantName}
            </div>
            <GiftCardBalance balance={giftCardValue} />
          </div>
          <div className='gap-4 mx-6 h-[162px] w-[288px] md:h-[193px] md:w-[343px] rounded-[20px] shadow bg-white overflow-hidden'>
            {eGiftCardImage ? (
              <GiftCardImage imageUrl={eGiftCardImage} />
            ) : (
              <RestaurantLogo
                placement='giftCard'
                className='h-full flex items-center justify-center'
                sizeRef='giftCard'
              />
            )}
          </div>
          <div className='m-6'>
            <div className='flex justify-center font-normal text-default whitespace-nowrap'>
              <span className='font-medium text-default mr-2'>{'For'}</span>
              {receiver === '' && (
                <span
                  style={{
                    display: 'table-cell',
                    borderBottom: '1px solid',
                    width: '137px',
                    borderColor: 'rgba(0, 0, 0, 0.08)'
                  }}
                >
                  {receiver}
                </span>
              )}
              <span
                className={'type-default font-semibold'}
                style={{
                  borderBottom: '1px solid',
                  borderColor: 'rgba(0, 0, 0, 0.08)'
                }}
              >
                {receiver}
              </span>
            </div>
            <div className='mt-2 mb-2 text-gray-100  text-wrap break-words overflow-hidden text-pretty'>
              <span>{message}</span>
              {sender?.from && (
                <span data-testid='sender-name'> - From {sender.from}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const GiftCardBalance = ({ balance = 0.0 }: GifCardBalanceProps) => {
  const { i18n } = useRestaurantContext()
  const { currency, locale } = i18n
  return (
    <div className='type-headline-6 text-right justify-end items-center'>
      {formatCurrency({ amount: balance, currency: currency }, locale)}
    </div>
  )
}
