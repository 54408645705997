import { Image } from '@toasttab/buffet-pui-image'
import React from 'react'
import { DEFAULT_GIFTCARD_IMAGE_URL } from '../../config'

export const DefaultGiftCardImage = (
  <Image
    containerClassName='flex justify-center h-full'
    src={DEFAULT_GIFTCARD_IMAGE_URL}
    alt='gift card'
  />
)

export const GiftCardImage = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <div data-testid='gift-card-image' className='row-span-2 w-full h-full'>
      <Image
        src={imageUrl}
        alt='gift card'
        fit='cover'
        containerClassName='w-full h-full'
        showError={true}
        errorComp={DefaultGiftCardImage}
      />
    </div>
  )
}
