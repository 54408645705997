import { ORDER_PROCESSING_BASE_URL } from './constants'

const SANDBOX_PREFIX = 'https://ws-sandbox-api.eng.toasttab.com'

export const SANDBOX_CONFIG = {
  toastWebBaseUrl: 'https://sandbox.eng.toasttab.com/',
  consumerBffUrl: `${SANDBOX_PREFIX}/do-federated-gateway/v1/graphql`,
  giftcardProcessingPlaceOrderUrl: `${SANDBOX_PREFIX}/${ORDER_PROCESSING_BASE_URL}/orders`,
  giftcardProcessingCheckoutValidationUrl: `${SANDBOX_PREFIX}/${ORDER_PROCESSING_BASE_URL}/orders/validate`,
  giftCardConfigUrl: `${SANDBOX_PREFIX}/${ORDER_PROCESSING_BASE_URL}/config/egiftcards`,
  recaptchaKey: '6LfyC3kpAAAAAM93w8htytwYEMwG8CaG9lUHGEt-',
  amplitudeApiKey: 'fabb3f63615483ceecd25751d57456b1'
}
