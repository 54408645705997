import React from 'react'
import PoweredByToast from '../assets/powered-by-toast.svg'

const Footer = () => {
  return (
    <footer className='text-center footer space-y-1'>
      <div className='type-caption text-gray-100 max-w-sm m-auto'>
        Gift cards are not redeemable for cash except as required by applicable
        law and then only to the extent required by applicable law.
      </div>
      <PoweredByToast />
      <div className='type-caption text-gray-100'>
        <div>
          <a href='https://pos.toasttab.com/privacy'>Privacy Statement</a> |{' '}
          <a
            href='https://pos.toasttab.com/terms-of-service'
            target='_blank'
            rel='noreferrer'
          >
            Terms of Service
          </a>
        </div>
        <div>© Toast, Inc. {new Date().getFullYear()} All Rights Reserved</div>
      </div>
      <div className='footer-banner'></div>
    </footer>
  )
}

export default Footer
