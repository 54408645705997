import React, { useState } from 'react'

import { Image } from '@toasttab/buffet-pui-image'
import { useRestaurantContext } from '@local/restaurant'
import cx from 'classnames'
import { DefaultGiftCardImage } from '../GiftCardImage/GiftCardImage'

export const sizeConfig: { [key: string]: string } = {
  default: 'h-[70px] w-[70px]',
  mobile: 'h-[50px] w-[50px]',
  giftCard: 'h-[110px] w-[110px]'
}

export const RestaurantLogo = ({
  sizeRef = 'default',
  className = '',
  placement = ''
}) => {
  const [error, setError] = useState<boolean>(false)
  const { logoUrls } = useRestaurantContext()
  const restaurantLogoSrc = logoUrls?.medium ?? ''
  /*
    When the restaurant logo url is not valid or falsy, show the default gift card image.
  */

  return (
    <div className={className} data-testid='restaurant-logo'>
      {error || !restaurantLogoSrc ? (
        placement === 'giftCard' && DefaultGiftCardImage
      ) : (
        <Image
          src={restaurantLogoSrc}
          alt='restaurant logo'
          testId='restaurant-logo'
          containerClassName={cx(sizeConfig[sizeRef], 'rounded-full')}
          fit='cover'
          onError={() => setError(true)}
        />
      )}
    </div>
  )
}
