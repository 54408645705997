import { ORDER_PROCESSING_BASE_URL } from './constants'

const PROD_PREFIX = 'https://ws-api.toasttab.com'

export const PROD_CONFIG = {
  toastWebBaseUrl: 'https://www.toasttab.com/',
  consumerBffUrl: `${PROD_PREFIX}/do-federated-gateway/v1/graphql`,
  giftcardProcessingPlaceOrderUrl: `${PROD_PREFIX}/${ORDER_PROCESSING_BASE_URL}/orders`,
  giftcardProcessingCheckoutValidationUrl: `${PROD_PREFIX}/${ORDER_PROCESSING_BASE_URL}/orders/validate`,
  giftCardConfigUrl: `${PROD_PREFIX}/${ORDER_PROCESSING_BASE_URL}/config/egiftcards`,
  recaptchaKey: '6LeXZp0pAAAAAO3UaxaMXbnYXp3v7hCjx5Lki2yo',
  amplitudeApiKey: '0176e802d1b68c3cb8294a5ac3def894'
}
