import { ORDER_PROCESSING_BASE_URL } from './constants'

const LOCAL_PREFIX = 'https://ws-preprod-api.eng.toasttab.com'

export const LOCAL_CONFIG = {
  toastWebBaseUrl: 'https://dev.eng.toastteam.com/',
  consumerBffUrl: `${LOCAL_PREFIX}/do-federated-gateway/v1/graphql`,
  giftcardProcessingPlaceOrderUrl: `${LOCAL_PREFIX}/${ORDER_PROCESSING_BASE_URL}/orders`,
  giftcardProcessingCheckoutValidationUrl: `${LOCAL_PREFIX}/${ORDER_PROCESSING_BASE_URL}/orders/validate`,
  giftCardConfigUrl: `${LOCAL_PREFIX}/${ORDER_PROCESSING_BASE_URL}/config/egiftcards`,
  recaptchaKey: '6LfyC3kpAAAAAM93w8htytwYEMwG8CaG9lUHGEt-',
  amplitudeApiKey: 'fabb3f63615483ceecd25751d57456b1'
}
