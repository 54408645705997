import { useMutation } from 'react-query'
import { useEffect, useState } from 'react'
import { getEnvConfig } from '../config'
import tracker from '@local/tracking/src/tracker'

declare global {
  interface Window {
    grecaptcha?: any
  }
}

export const useReCaptcha = () => {
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState<boolean>(false)
  const envConfig = getEnvConfig()
  const RECAPTCHA_KEY = envConfig.recaptchaKey

  useEffect(() => {
    if (isRecaptchaLoaded) return

    if (window.grecaptcha) {
      setIsRecaptchaLoaded(true)
      return
    }
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`
    script.addEventListener('load', () => {
      setIsRecaptchaLoaded(true)
    })
    document.body.appendChild(script)
  }, [RECAPTCHA_KEY, isRecaptchaLoaded])

  const generateReCaptchaToken = (action: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!isRecaptchaLoaded) {
        tracker.track('ReCaptcha Error', { error: 'ReCaptcha not loaded' })
        return reject(new Error('ReCaptcha not loaded'))
      }
      if (typeof window === 'undefined' || !window.grecaptcha) {
        setIsRecaptchaLoaded(false)
        tracker.track('ReCaptcha Error', { error: 'ReCaptcha not loaded' })
        return reject(new Error('ReCaptcha not loaded'))
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(RECAPTCHA_KEY, { action })
          .then((token: string) => {
            resolve(token)
          })
      })
    })
  }

  const getRecaptcha = useMutation(
    (action: string) => generateReCaptchaToken(action),
    {
      retry: true,
      onSuccess: () => {
        tracker.track('ReCaptcha Success')
      },
      onError: (error: object) => {
        tracker.track('ReCaptcha Error', error)
      }
    }
  )
  return { isRecaptchaLoaded, getRecaptcha }
}
