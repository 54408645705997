import React from 'react'

// todo: remove me after upgrading form package
export const Spinner = () => (
  <svg
    aria-hidden='true'
    role='status'
    className='inline mr-2 h-4 w-4 animate-spin'
    viewBox='0 0 100 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='50'
      cy='50'
      r='45'
      stroke='#FFFFFFB2'
      strokeWidth='10'
      fill='none'
    ></circle>
    <circle
      cx='50'
      cy='50'
      r='45'
      stroke='currentColor'
      strokeWidth='10'
      strokeDasharray='283'
      strokeDashoffset='65'
      fill='none'
    ></circle>
  </svg>
)
