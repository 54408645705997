import axios, { AxiosPromise } from 'axios'
import { getEnvConfig } from '../config'
import {
  GiftCardConfigResponse,
  GiftCardOrderResponse,
  CheckoutValidationRequest,
  GiftCardRequest
} from '../app/types'

export const submitGiftCardOrder = (
  request: GiftCardRequest,
  restaurantGuid: string
): AxiosPromise<GiftCardOrderResponse> => {
  const { giftcardProcessingPlaceOrderUrl } = getEnvConfig()
  return axios.post(giftcardProcessingPlaceOrderUrl, request, {
    headers: {
      'Content-Type': 'application/json',
      'Toast-Restaurant-External-ID': restaurantGuid
    }
  })
}

export const validateGiftCardCheckout = (
  request: CheckoutValidationRequest,
  restaurantGuid: string
): AxiosPromise<void> => {
  const { giftcardProcessingCheckoutValidationUrl } = getEnvConfig()
  return axios.post(giftcardProcessingCheckoutValidationUrl, request, {
    headers: {
      'Content-Type': 'application/json',
      'Toast-Restaurant-External-ID': restaurantGuid
    }
  })
}

export const getGiftCardConfig = (
  restaurantGuid: string
): AxiosPromise<GiftCardConfigResponse> => {
  const { giftCardConfigUrl } = getEnvConfig()
  return axios.get(giftCardConfigUrl, {
    headers: {
      'Content-Type': 'application/json',
      'Toast-Restaurant-External-ID': restaurantGuid
    }
  })
}
